// Color scheme
$bg-pri: #122440;
$bg-sec: #EBF0F2;
$bg-ter: #706C61;
$bg-pri2: #233551;

// Font Colors

$fc-pri: #FFFDF4;
$fc-sec: #706C61;
$fc-ter: #233551;
$fc-qua:#7BC0FF;
$fc-qui: #939088;

// Font Sizes
$fs-xs: 0.8rem;
$fs-sm: 1rem;
$fs-md: 1.4rem;
$fs-lg: 2rem;
$fs-xlg: 3rem;

// Initial Animation
$t-init: 1.5s;

//Section Separating Padding
$sec-pad-top: 6rem;


:export {
    bgSec: $bg-sec;
    fcQua: $fc-qua;
}