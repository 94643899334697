@keyframes moveUp1 {
  0% {
    transform: translateY(40rem);
  }
  60% {
    transform: translateY(-2rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveUp2 {
  0% {
    transform: translateY(25rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes moveUp3 {
  0% {
    transform: translateY(20rem);
  }
  40% {
    transform: translateY(-3rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
:export {
  tabPort: 767;
}

:export {
  bgSec: #EBF0F2;
  fcQua: #7BC0FF;
}

:root {
  --service-bar: $bg-sec;
}

body {
  margin: 0;
  font-size: 14px;
  width: 100%;
  font-family: "Mulish", sans-serif;
  overflow-x: hidden;
  box-sizing: border-box;
}

.wcontainer {
  padding-left: 13%;
  padding-right: 13%;
}
@media (max-width: 1400px) {
  .wcontainer {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.noscroll {
  position: fixed;
  overflow-y: hidden;
  width: 100%;
}

h1 {
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}

h4 {
  color: #706C61;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

.absolute {
  position: "absolute";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
}
.absolute--blue {
  background-color: rgba(18, 36, 64, 0.5);
}

.footer {
  background-color: #706C61;
  text-align: center;
  color: #FFFDF4;
}

.header {
  background-color: #122440;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;
}
.header__navbar {
  flex: 0 1 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.header__navbar__nav-item {
  width: 100%;
  min-width: 10rem;
}
@media (max-width: 767px) {
  .header__navbar__nav-item {
    min-width: 7rem;
  }
}
.header__body {
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
}
.header__body__container__slogan {
  font-size: 1.4rem;
  color: #FFFDF4;
}

.navbar__scroll {
  background-color: #122440;
}

@media (max-width: 600px) {
  .navbar-brand {
    width: 100%;
    display: block;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .ms-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.nav-link {
  font-size: 1rem;
  font-family: "Inter", sans-serif;
  color: #FFFDF4;
  margin-right: 1rem;
  text-align: center;
}

.navbar-nav {
  flex-direction: row;
}

.nav-link:focus, .nav-link:hover {
  animation: swing;
  animation-duration: 1s;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.nav-link, .navbar-brand {
  cursor: pointer;
  user-select: none;
}

.wbody {
  color: #706C61;
  height: auto;
  background-color: #EBF0F2;
}
.wbody__svgs {
  position: relative;
}
.wbody__svgs__svg {
  position: absolute;
  top: -8rem;
  z-index: 0;
  width: 100%;
  height: auto;
}
.wbody__svgs__svg--1 {
  animation: moveUp1;
  animation-duration: 1.5s;
  animation-timing-function: ease;
}
.wbody__svgs__svg--2 {
  animation: moveUp2;
  animation-duration: 1.5s;
}
.wbody__svgs__svg--3 {
  animation: moveUp3;
  animation-duration: 1.5s;
}
.wbody__content {
  position: relative;
}
.wbody__content__section {
  padding: 7% 10%;
}
@media (max-width: 767px) {
  .wbody__content__section {
    padding: 5% 3%;
  }
}
.wbody__content__section__container {
  padding: 5rem 0;
}
.wbody__content__section__container__col {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.wbody__content__section__container__col__row {
  margin: 10vh 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.wbody__content__section__container__col__row--pic {
  padding: 2rem;
}
.wbody__content__section__container__col__row--pic img {
  height: auto;
  width: 100%;
}
.wbody__content__section__container__col__row--info {
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wbody__content__section--tech {
  max-width: 100%;
}
.wbody p {
  font-size: 2rem;
}
@media (max-width: 600px) {
  .wbody p {
    font-size: 1.4rem;
  }
}

.bgcolor--pri {
  background: linear-gradient(to right, #122440, #233551, #122440);
  color: #FFFDF4;
}

.bgcolor--sec {
  background: #EBF0F2;
  color: #233551;
}

.wbody__section {
  padding-top: 1rem;
  width: 100%;
  min-height: 90vh;
}
.wbody__section__div {
  height: 0.25rem;
  width: 7rem;
}
.wbody__section:not(:first-child) {
  padding-top: 6rem;
}

.challenges {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}
@media (max-width: 767px) {
  .challenges {
    display: block;
  }
}
.challenges__infocard {
  grid-row: 1/2;
  text-align: center;
  padding: 2rem;
}
.challenges__infocard__number {
  font-size: 4rem;
  font-weight: 500;
}

.accordion {
  background-color: inherit;
}
.accordion-body p {
  font-size: 1rem;
  color: #939088;
}

.accordion-button {
  background-color: inherit;
  color: white;
}
.accordion-button::after {
  display: none;
}

.accordion-item {
  margin: 2rem 0;
  background-color: inherit;
  position: relative;
  cursor: pointer;
}

.accordion-item::after {
  display: inline-block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  background-color: var(--service-bar);
  content: "";
}

.accordion:focus, .accordion-button:focus, .accordion-header:focus, .accordion-item {
  border: none;
  outline: none;
  background-color: inherit;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: inherit;
  outline: none;
  box-shadow: none;
}

.services2 {
  display: grid;
  gap: 2rem;
  grid-template-columns: 45% 55%;
  grid-template-rows: auto;
}
@media (max-width: 767px) {
  .services2 {
    display: block;
  }
}

.lottie {
  margin: auto;
}
@media (max-width: 1800px) {
  .lottie {
    max-height: 500px;
    max-width: 80%;
  }
}
@media (max-width: 767px) {
  .lottie {
    max-width: 50%;
    max-height: 300px;
  }
}

.hwdi {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  gap: 2rem;
}
.hwdi__card {
  cursor: pointer;
  padding: 1rem 0;
}
.hwdi__card:not(:last-child) {
  border-bottom: 1px solid black;
}
.hwdi__card-selected {
  color: #233551;
}
.hwdi__card-noselect {
  color: #939088;
}
@media (max-width: 990px) {
  .hwdi__card--info {
    font-size: 1rem;
  }
}
.hwdi__img {
  text-align: center;
}
@media (max-width: 1200px) {
  .hwdi__img {
    text-align: left;
  }
}
.hwdi__img > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
@media (max-width: 767px) {
  .hwdi {
    display: block;
    grid-template-columns: 1fr;
  }
  .hwdi__img {
    width: 100%;
    text-align: center;
  }
  .hwdi__img > img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 20%;
  }
}

.services {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}
@media (max-width: 767px) {
  .services {
    max-width: 80%;
    margin: 0 auto;
    flex-direction: column;
  }
}

.servicecard {
  background-color: #122440;
  color: #233551;
  width: 30%;
  padding: 0 0 2rem 0;
  box-shadow: 0.1rem 0.1rem 0.3rem black;
  text-align: center;
  position: relative;
  border-radius: 5%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .servicecard {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .servicecard:not(:first-child) {
    margin-top: 5rem;
  }
}
@media (max-width: 767px) {
  .servicecard:not(:last-child) {
    margin-bottom: 5rem;
  }
}
.servicecard div {
  padding: 1rem 0.5rem;
}
.servicecard i {
  font-size: 3rem;
}
.servicecard p {
  font-size: 1rem;
}

.servicecard {
  background-color: #EBF0F2;
}
.servicecard--top {
  background-size: cover;
  border-radius: 5% 5% 0% 0%;
  height: 15rem;
}
.servicecard--header {
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: 150%;
  background-color: #EBF0F2;
  opacity: 0.9;
  transform: rotate(-25deg) translate(-35%, -3rem);
}
.tech {
  color: white;
  text-align: center;
}
.tech div {
  text-align: center;
  padding: 3rem 0;
  display: inline-block;
}
.tech p {
  font-weight: 400;
  font-size: 1.4rem;
}

.form-disappear {
  animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
input, textarea {
  margin: 1rem 0;
  padding: 0.7rem;
  font-size: 1rem;
  display: block;
  width: 100%;
  max-width: 50rem;
  border: none;
  border-bottom: 1px solid black;
  background-color: inherit;
}

input, textarea:focus {
  outline: none;
}

input {
  max-width: 20rem;
}

textarea {
  max-width: 40rem;
}

button {
  border-radius: 50px;
  font-size: 1rem;
  padding: 1.2rem 4rem;
  background-color: #122440;
  color: white;
}
button::before {
  display: none;
}

