.wbody{
    color: $fc-sec;
    height: auto;
    background-color: $bg-sec;
    &__svgs{
        position: relative;
        &__svg{
            position: absolute;
            top: -8rem;
            z-index: 0;
            width: 100%;
            height: auto;
            &--1{
                animation: moveUp1;
                animation-duration: $t-init;
                animation-timing-function: ease;
            }
            &--2{
                animation: moveUp2;
                animation-duration: $t-init;
            }
            &--3{
                animation: moveUp3;
                animation-duration: $t-init;
            }
        }
        
    }
    &__content{
        position: relative;
        &__section{
            padding: 7% 10%;
            @include respond(tab-port){
                padding: 5% 3%;
            }
            &__container{
                &__col{
                    display: grid;
                    grid-template-rows: 1fr 1fr 1fr;
                    &__row{
                        margin: 10vh 0;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        &--pic{
                            & img{
                                height: auto;
                                width: 100%;
                            }
                            padding: 2rem;
                        }
                        &--info{
                            padding: 2rem;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                    }
                }
                padding: 5rem 0;
            }
            &--tech{
                max-width: 100%;
            }
            &--services{
                
            }
            
        }
    }
    & p{
        font-size: $fs-lg;
        @include respond(phone){
            font-size: $fs-md;
        }
    }
}

//Sections

.bgcolor--pri{
    background: linear-gradient(to right, $bg-pri, $bg-pri2, $bg-pri) ;
    color: $fc-pri;
}

.bgcolor--sec{
    background: $bg-sec;
    color: $fc-ter;
}

.wbody{
    &__section{
        padding-top: 1rem;
        width: 100%;
        min-height: 90vh;
        &__div{
            height: 0.25rem;
            width: 7rem
        }
    }
    &__section:not(:first-child){
        padding-top: $sec-pad-top;
    }
}
//Challenges

.challenges{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    @include respond(tab-port){
        display: block;
    }
    &__infocard{
        grid-row: 1 / 2;
        text-align: center;
        padding: 2rem;
        &__number{
            font-size: 4rem;
            font-weight: 500;
        }
    }
    
}

//Service 2
//Accordian

.accordion{
    background-color: inherit;
    &-body p{
        font-size: $fs-sm;
        color: $fc-qui;
    }
}
.accordion-button{
    background-color: inherit;
    color: white;
    &::after {
        // Get rid of icons
        display: none;
    }
    
}
.accordion-item{
    margin: 2rem 0;
    background-color: inherit;
    position: relative;
    cursor: pointer;
}

.accordion-item::after{
    display: inline-block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    background-color: var(--service-bar);
    content: "";
}

.accordion:focus, .accordion-button:focus, .accordion-header:focus, .accordion-item{
    border:none;
    outline: none;
    background-color: inherit;
    box-shadow: none;
}

.accordion-button:not(.collapsed){
    color: inherit;
    background-color: inherit;
    outline: none;
    box-shadow: none;
}

.services2{
    
    display: grid;
    
    gap: 2rem;
    grid-template-columns: 45% 55%;
    grid-template-rows: auto;
    @include respond(tab-port){
        display: block;
    }
}
.lottie{
    
    margin: auto;
    @include respond(big-desktop){
        max-height: 500px;
        max-width: 80%;
    }
    @include respond(tab-port){
        max-width: 50%;
        max-height: 300px;
    }
}


// HWDI Card
.hwdi{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    gap: 2rem;
    &__card{
        cursor: pointer;
        &:not(:last-child){
            // Only works if all children are under the same parent, o/w they are all alst children
            border-bottom: 1px solid black;
        }
        padding: 1rem 0;
        &-selected{
            color: $fc-ter;
        }
        &-noselect{
            color: $fc-qui;
        }
        &--info{
            @include respond(tab-half){
                font-size: $fs-sm;
            }
            
        }
    }
    &__img{
        // height: 100%;
        text-align: center;
        @include respond(tab-land){
            text-align: left;
        }
        & > img{
            max-width:100%;
            max-height:100%;
            height: auto;
        }
    }
    @include respond(tab-port){
        display: block;
        grid-template-columns: 1fr;
        &__img{
            width: 100%;
            text-align: center;
            & > img{
                height: 400px;
                width: 100%;
                object-fit: cover;
                object-position: 50% 20%;
            }
        }
    }
}





// Service Card
.services{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    @include respond(tab-port){
        max-width: 80%;
        margin: 0 auto;
        flex-direction: column;
    }
}

.servicecard{
    background-color: $bg-pri;
    color: $fc-ter;
    width: 30%;
    padding: 0 0 2rem 0;
    box-shadow: 0.1rem 0.1rem 0.3rem black;
    @include respond(tab-land){
    }
    @include respond(tab-port){
        width: 80%;
        margin: 0 auto;
    }
    &:not(:first-child){
        @include respond(tab-port){
            margin-top: 5rem;
        }
    }
    &:not(:last-child){
        @include respond(tab-port){
            margin-bottom: 5rem;
        }
    }
    text-align: center;
    position: relative;
    border-radius: 5%;
    overflow: hidden;
    & div{
        padding: 1rem 0.5rem;
    }
    & i{
        font-size: $fs-xlg;
    }
    & p{
        font-size: $fs-sm;
    }

}
.servicecard{
    background-color: $bg-sec;
    &--top{
        background-size: cover;
        border-radius: 5% 5% 0% 0%;
        height: 15rem;
    }
    &--header{
        position: absolute;
        top: 0;
        margin: 0 auto;
        width: 150%;
        background-color: $bg-sec;
        opacity: 0.90;
        transform: rotate(-25deg) translate(-35%, -3rem);
    }
}

.tech{
    color: white;
    text-align: center;
    & div{
        text-align: center;
        padding: 3rem 0;
        display: inline-block;
    }
    & p{
        font-weight: 400;
        font-size: $fs-md;
    }
}

.form-disappear {
animation: fadeOut 1s forwards;
}


input, textarea{
    margin: 1rem 0;
    padding: 0.7rem;
    font-size: $fs-sm;
    display: block;
    width: 100%;
    max-width: 50rem;
    border: none;
    border-bottom: 1px solid black;
    background-color: inherit;
}

input, textarea:focus{
    outline: none;
}

input{
    max-width: 20rem;
}

textarea{
    max-width: 40rem;
}

button{
    border-radius: 50px;
    font-size: $fs-sm;
    padding: 1.2rem 4rem;
    background-color: $bg-pri;
    color: white;
    &::before{
        display: none;
    }
}