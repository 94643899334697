.absolute {
    position: 'absolute';
    top: 0;
    left: 0;
    width: '100%';
    height: '100%';
    &--blue{
        background-color: rgba(#122440, 0.5);
    }
  }
    

