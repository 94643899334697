// Wave Animations on startup
@keyframes moveUp1 {
    0%{
        transform: translateY(40rem);
    }
    60%{
        transform: translateY(-2rem);
    }
    100%{
        transform: translateY(0);
    }
    
}

@keyframes moveUp2{
    0%{
        transform: translateY(25rem);
    }
    100%{
        transform: translateY(0);
    }
}


@keyframes moveUp3 {
    0%{
        transform: translateY(20rem);
    }
    40%{
        transform: translateY(-3rem);
    }
    100%{
        transform: translateY(0);
    }
}

// animation for form completion
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}