.header{
    background-color: $bg-pri;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column;
    &__navbar{
        flex: 0 1 auto;
        position: fixed;
        top:0;
        left:0;
        right: 0;
        z-index: 9999; 
        &__nav-item{
            width: 100%;
            min-width: 10rem;
            @include respond(tab-port){
                min-width: 7rem;
            }
        }
    }

    &__body{
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-25%, -50%);
        &__container{
            &__slogan{
                // Temp
                font-size: $fs-md;
                color: $fc-pri;
            }
        }
    }
}

// Override Navbar section
.navbar__scroll{
    background-color: $bg-pri;
}

.navbar-brand{
    @include respond(phone){
        width: 100%;
        display:block;
        text-align: center;
    }
    
}

.ms-auto{
    @include respond(phone){
        margin-left: auto!important;
        margin-right: auto!important;
    }
}

.nav-link{
    font-size: $fs-sm;
    font-family: 'Inter', sans-serif;
    color: $fc-pri;
    margin-right: 1rem;
    text-align: center;
}


.navbar-nav{
    flex-direction: row;
}
.nav-link:focus, .nav-link:hover{
    animation: swing;
    animation-duration: 1s;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    padding-right: 0;
    padding-left: 0;
}

.nav-link, .navbar-brand{
    cursor: pointer;
    user-select: none;
}