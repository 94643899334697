@mixin respond($breakpoint){
    @if $breakpoint == phone{
        @media (max-width: 600px){@content};
    }
    @if $breakpoint == tab-port{
        @media (max-width: 767px){@content};
    }
    @if $breakpoint == tab-half{
        @media (max-width: 990px){@content};
    }
    @if $breakpoint == tab-land{
        @media (max-width: 1200px){@content};
    }
    @if $breakpoint == tab-full{
        @media (max-width: 1400px){@content};
    }
    @if $breakpoint == big-desktop{
        @media (max-width: 1800px){@content};
    }
}

:export{
    tabPort: 767;
}