:root{
  --service-bar: $bg-sec;
}

body {
    margin: 0;
    font-size: 14px;
    width: 100%;
    // font-family: 'Inter', sans-serif;
    font-family: 'Mulish', sans-serif;
    overflow-x: hidden;
    box-sizing: border-box;
    
}

.wcontainer{
  
  padding-left: 13%;
  padding-right: 13%;

  @include respond(tab-full){
    padding-left: 5%;
    padding-right: 5%;
  }
}

.noscroll { 
  position: fixed;
  overflow-y: hidden;
  width: 100%;
}

h1{
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
}

h4{
  color: $fc-sec;
}

h5{
  font-size: $fs-sm;
}

h6{
  font-size: $fs-sm;
}